export const fields = [
    { key: 'index', label: "No", _style:'width:10px'},
    { key: 'select', label: "", _style:'idth:10px' },
    { key: 'nama', label: "Nama", _style:'min-width:100px' },
    { key: 'tanggal_perkiraan_menikah', label: "Tanggal Perkiraan Menikah", _style:'min-width:100px' },
    { key: 'nip', label: "Nip", _style:'min-width:50px' },
    { key: "tempat_lahir", label: "Tempat Lahir", _style:'min-width:50px'},
    { key: "no_hp", label: "No HP", _style:'min-width:50px'},
    { key: "kecamatan", label: "Kecamatan", _style:'min-width:50px'},
    { key: "kelurahan", label: "Kelurahan", _style:'min-width:50px'},
    { key: "alamat", label: "Alamat", _style:'min-width:50px'},
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diupdate", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]